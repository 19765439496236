






























































































































@import "../styles/env";

.front-page {
  position: relative;

  overflow: hidden;

  background: url("/images/backgrounds/home.jpg") center fixed;
  background-size: cover;

  perspective: 800px;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0.35) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0.35) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0.35) 100%);

    content: "";

    @media #{$media-mobile} {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

main {
  width: 100%;
  max-width: 68rem;
  margin: 0;
  padding-top: 10rem;
  padding-bottom: 10rem;

  position: relative;
  z-index: 3;

  color: #FFF;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);

  background: none;

  @media #{$media-mobile} {
    margin: 0 auto;

    text-align: center;
  }

  @media #{$media-tiny} {
    padding-top: 10rem;
    padding-bottom: 5rem;

    .standalone & {
      padding-top: 5rem;
    }
  }
}

img {
  @media #{$media-mobile} {
    margin: 0 auto;
  }
}

h1 {
  padding-top: 2rem;
  /*padding-bottom: 4rem;*/

  //color: $primary-colour;
  font-size: 8rem;
  letter-spacing: -0.2rem;

  @media #{$media-tiny} {
    font-size: 5rem;
  }
}

h2 {
  padding-top: 1rem;
  padding-bottom: 1rem;

  font-weight: 400;
}

hr {
  background-color: rgba(255, 255, 255, 0.25);
}

.search-form {
  margin-top: 3rem;
}

.hero-buttons {
  padding-top: 1rem;
  padding-bottom: 8rem;

  a {
    width: 100%;

    box-sizing: border-box;

    text-align: center;
  }
}

.stat {
  max-width: 20rem;
  padding-top: 3rem;

  @media #{$media-mobile} {
    max-width: none;

    text-align: center;
  }

  @media #{$media-tiny} {
    br {
      /*display: none;*/
    }
  }

  strong {
    font-size: 3rem;
  }

  span {
    /*font-size: 2.5rem;*/
  }
}

footer {
  position: relative;
  z-index: 3;
}

.photos {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  transform: rotateX(3deg) rotateY(3deg);
  /*transform-origin: 100% 50%;*/
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.photos-button-wrapper {
  width: 37.5vw;
  max-width: 450px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  transform: translateZ(101px);

  &:hover {
    .photos-button {
      opacity: 1;
    }
  }
}

.photos-button {
  transition: opacity 200ms;

  opacity: 0;
}
