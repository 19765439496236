























































footer {
  padding-top: 5rem;
  padding-bottom: 5rem;

  color: #FFF;

  /*background-color: #424242;*/
  /*background-color: #F5F5F5;*/

  a {
    color: #FFF;
  }
}

.container {
  /*padding-bottom: 5rem;*/
}

.pipe {
  width: 1px;

  background-color: rgba(255, 255, 255, 0.15);
}

p {
  padding-bottom: 0.5rem;
}
