





























































































@import "../styles/env";

.bottom-nav {
  position: sticky;
  bottom: -1px;
  z-index: 11;

  .divider {
    width: 1px;

    background-color: rgba(#FFF, 0.1);
  }
}

.bottom-nav-items {
  position: relative;
  z-index: 3;

  background-color: #212121;

  @include shadow-8;

  a,
  button {
    padding: 2rem;

    color: #FFF;
    text-decoration: none;
    line-height: 1;

    @media #{$media-mobile} {
      padding: 1.25rem 0.75rem;
    }

    .material-icons {
      margin-right: 2rem;

      display: inline-block;

      color: $primary-colour-light;

      @media #{$media-mobile} {
        margin-right: 0;
        margin-bottom: 0.5rem;

        display: block;

        font-size: 2.5rem;
      }
    }

    span {
      font-size: 2rem;

      @media #{$media-mobile} {
        font-size: 1.5rem;
      }
    }

    &:hover {
      color: #FFF;
    }

    &.active,
    &.router-link-active {
      color: $base-font-colour;

      //@include primary-grad-background;
      background-color: #FFF;

      .material-icons {
        color: $primary-colour-dark;

        &.selected-colour-red {
          color: $favourite-colour;
        }

        &.selected-colour-highlight-1 {
          color: $primary-highlight-1;
        }

        &.selected-colour-highlight-2 {
          color: $primary-highlight-2;
        }
      }
    }
  }
}

.more-menu {
  max-height: 0;
  padding: 1rem 2rem 2rem;

  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  z-index: 2;

  overflow: hidden;

  background: #fff;
  // background: $primary-colour;
  // background: -moz-linear-gradient(top, $primary-colour 0%, $primary-colour-dark 100% );
  // background: -webkit-linear-gradient(top, $primary-colour 0%, $primary-colour-dark 100% );
  // background: linear-gradient(to bottom, $primary-colour 0%, $primary-colour-dark 100% );

  @include shadow-4;

  transform: translateY(100%);

  transition: max-height 400ms, transform 400ms, opacity 400ms;

  opacity: 0;

  &.show {
    max-height: 100vh;

    transform: translateY(0);

    opacity: 1;
  }

  a {
    padding: 2rem;

    display: block;

    color: $base-font-colour;
    text-decoration: none;

    &:not(.small) + a {
      border-top: 1px rgba(#000, 0.1) solid;
    }

    .material-icons {
      margin-left: 1rem;
    }

    .fab {
      margin-right: 1rem;

      font-size: 2.5rem;
      vertical-align: middle;

      &.fa-facebook {
        color: $facebook;
      }

      &.fa-twitter {
        color: $twitter;
      }

      &.fa-instagram {
        background: -webkit-linear-gradient(#feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.fa-skype {
        color: $skype;
      }

      @media #{$media-mobile} {
        margin: 0 auto;

        display: block;
      }
    }

    &.small {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;

      /*font-size: 1.75rem;*/
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 1rem;

    background-color: rgba(#000, 0.1);
  }
}

.more-menu-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background-color: rgba(#000, 0.75);

  transform: translateY(100%);

  transition: transform 1ms 399ms, opacity 399ms;

  opacity: 0;

  &.show {
    transform: translateY(0);

    transition: transform 1ms, opacity 399ms 1ms;

    opacity: 1;
  }
}
