.sidebar {
  width: 100%;
  height: 7rem;
  padding: 0 $gutter-mobile;

  box-sizing: border-box;

  overflow: auto;

  position: sticky;
  top: 7rem;
  z-index: 4;

  text-align: left;
  white-space: nowrap;

  @include shadow-4;

  @include flex(none);

  .standalone & {
    top: 0;

    box-shadow: none;
  }

  h4,
  a {
    height: 100%;
    margin-right: 2.5rem;
    padding: 0 1rem;

    display: inline-block;

    overflow: hidden;

    line-height: 7rem;
    white-space: nowrap;
    vertical-align: top;

    &::after {
      height: 0.5rem;

      top: 100%;
      bottom: auto;

      background: rgba(255, 255, 255, 0.5) !important;

      border-radius: 0.5rem 0.5rem 0 0;

      transition: transform $transition-hover-in;

      content: "";
    }
  }

  a {
    &.router-link-exact-active {
      border-right-width: 0;

      &::after {
        transform: translateY(-100%);

        transition-delay: $transition-hover-in;
      }
    }
  }
}
