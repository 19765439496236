







































@import "../styles/env";

.notice {
  width: 100rem;
  max-width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /*padding: 2rem 2rem 4rem 3rem;*/
  padding: 4rem 3rem;

  color: #FFF;

  background-color: $primary-colour;

  border-radius: $border-radius;

  @include shadow-6;

  transition: transform 200ms ease-in;

  &.dismissed {
    transform: translateX(100vw);
  }
}

.notice-body {
  /*margin-right: 2rem;*/
  /*padding-top: 2rem;*/
  padding-bottom: 3rem;

  .date {
    margin-bottom: 1rem;
  }

  p {
    padding-bottom: 0.5rem;
  }
}
