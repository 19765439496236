$primary-colour: #3F51B5;
$primary-colour-light: #5C6BC0;
$primary-colour-really-light: #C5CAE9;
$primary-colour-dark: #303F9F;

$favourite-colour: #F44336;

$ui-good-colour: #6DBAA1;
$ui-bad-colour: #F44336;

//$primary-grad-colour-1: #B34FAE;
//$primary-grad-colour-2: #F65D91;
//$primary-grad-colour-3: #FF886F;
//$primary-grad-colour-4: #FFC05C;
//$primary-grad-colour-5: #F9F871;

$primary-highlight-1: #8987BC;
$primary-highlight-1-light: #F0EDFF;
$primary-highlight-2: #6DBAA1;

$base-font-colour: #212121;
$base-font-family: "IBM Plex Sans", sans-serif;
$base-font-size-large: 2rem;
$base-line-height-large: 1.5;
$base-font-size-mobile: 2rem;
$base-line-height-mobile: 1.5;
$base-font-weight: 400;

$transition-hover-in: 100ms;
$transition-hover-out: 250ms;
$transition-animate: 400ms;

$largest-tiny-device: 480px;
$largest-small-device: 768px;
$largest-medium-device: 1024px;
$largest-width: 1280px;

$media-large: "(min-width: #{$largest-medium-device + 1px})";
$media-lt-large: "(max-width: #{$largest-medium-device})";
$media-medium: "(min-width: #{$largest-small-device + 1px}) and (max-width: #{$largest-medium-device})";
$media-mobile: "(max-width: #{$largest-small-device})";
$media-small: "(min-width: #{$largest-tiny-device + 1px}) and (max-width: #{$largest-small-device})";
$media-tiny: "(max-width: #{$largest-tiny-device})";

$gutter: 4rem;
$gutter-mobile: 2rem;

$border-radius: 2px;

$facebook: #3B5998;
$twitter: #1DA1F2;
$skype: #00AFF0;
