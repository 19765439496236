
































































































































@import "../../styles/env";

.location-title {
  margin-bottom: 2rem;

  .standalone & {
    margin-bottom: 0;
  }
}
