h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: (4 / 3);
}

h2,
h3,
h4,
h5,
h6 {
  padding-bottom: 2rem;

  &:last-child {
    padding-bottom: 0;
  }
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 3.75rem;
}

h3 {
  font-size: 2.75rem;
}

h4 {
  font-size: 2.25rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1.8rem;
}

p,
ul,
ol {
  padding-bottom: 2rem;

  &:last-child {
    padding-bottom: 0;
  }
}

ul,
ol {
  padding-left: 4rem;

  list-style-position: outside;

  &.plain {
    padding-left: 0;

    list-style: none;
  }
}

li {
  padding-top: 1rem;

  &:first-child {
    padding-top: 0;
  }
}

dl {
}

dt,
dd {
  padding-top: 1rem;

  display: inline-block;

  vertical-align: top;
}

dt {
  width: calc(40% - #{($gutter / 2)});
  padding-right: ($gutter / 2);

  &:first-child {
    padding-top: 0;

    + dd {
      padding-top: 0;
    }
  }
}

dd {
  width: calc(60% - #{($gutter / 2)});
  padding-left: ($gutter / 2);

  + dd {
    margin-left: 40%;
    padding-top: 0;
  }
}

.large-text {
  h1 {
    padding-bottom: 0;

    font-size: 7em;
    line-height: 1;
  }

  p {
    padding-top: 0;
    padding-bottom: 1em;

    font-size: 3em;
  }
}
