.status,
p.success,
p.info,
p.warning,
p.error {
  background-color: #F9F9F9;

  border: 1px #D3D3D3 solid;
  border-radius: $border-radius;
}

p.success,
p.info,
p.warning,
p.error {
  margin-bottom: 2rem;
  padding: 1rem 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.status.success,
p.success {
  background-color: #DCEDC8;

  border-color: #689F38;
}

.status.info,
p.info {
  background-color: #B3E5FC;

  border-color: #0288D1;
}

.status.warning,
p.warning {
  background-color: #FFECB3;

  border-color: #FFA000;
}

.status.error,
p.error {
  background-color: #FFCDD2;

  border-color: #D32F2F;
}
