.ad {
  margin: $gutter auto;

  display: block;

  font-size: 0;
  text-align: center;

  a {
    display: inline-block;
  }

  img {
  }
}
