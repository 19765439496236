























































































@import "../../styles/env";

.wrapper {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 100%;
  left: 0;

  //overflow: hidden;

  /*background-color: rgba(0, 0, 0, 0.85);*/

  opacity: 0;

  transition: top 1ms 400ms, opacity $transition-hover-out, transform $transition-hover-out;

  transform: scale(0.5);

  &.show {
    top: 0;

    opacity: 1;

    transition: top 1ms, opacity $transition-hover-out 1ms, transform $transition-hover-out 1ms;

    transform: scale(1);
  }
}

.report {
  max-width: calc(100% - 5rem);
  width: 500px;
  max-height: 100%;

  position: relative;

  overflow-x: hidden;
  overflow-y: auto;

  box-sizing: border-box;

  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.85);
}

.hide {
  width: 4rem;
  height: 4rem;

  position: absolute;
  top: -1rem;
  right: -1rem;
  z-index: 2;

  color: #FFF;

  background: $primary-colour;

  border-radius: 100%;
}
