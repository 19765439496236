.card {
  margin-bottom: $gutter;

  display: block;

  position: relative;

  //overflow: hidden;

  background-color: #FFF;

  border-radius: $border-radius;

  @include shadow-2;

  @media #{$media-mobile} {
    margin-bottom: $gutter-mobile;
  }

  .column & {
    //height: 100%;
  }

  &.placeholder {
    .card-image {
      padding-bottom: (2 / 3) * 100%;

      display: block;
    }

    .card-image,
    .card-title:before,
    .card-subtitle:before {
      @include animated-background;
    }

    .card-title,
    .card-subtitle {
      padding: 1.5rem;

      &:before {
        height: 1rem;

        display: block;

        content: "";
      }
    }

    .card-title {
      &:before {
        width: 65%;
        height: 2.25rem;
      }
    }

    .card-subtitle {
      &:before {
        width: 90%;
        height: 2rem;
      }
    }

    //@for $i from 1 through 24 {
    //&.animation-offset-#{$i} {
    //  .card-image,
    //  .card-title:before,
    //  .card-subtitle:before {
    //    animation-delay: ($i - 1) * 25ms;
    //  }
    //}
    //}
  }

  &[data-label] {
    &::before {
      padding: 0 1rem;

      display: inline-block;

      position: absolute;
      top: 1rem;
      left: 1rem;

      color: #FFF;

      background-color: #424242;

      @include shadow-4;

      content: attr(data-label);
    }
  }

  &.label-red {
    &::before {
      background-color: #D32F2F;
    }
  }

  &[data-count] {
    &::after {
      padding: 0 1rem;

      display: inline-block;

      position: absolute;
      top: 1rem;
      right: -0.25rem;

      color: #FFF;

      background-color: $primary-colour;
      //background-color: #FFF;

      @include shadow-4;

      content: attr(data-count);
    }

    &[data-countunits] {
      &::after {
        content: attr(data-count) " " attr(data-countunits);
      }
    }
  }

  &[data-count=""] {
    &::after {
      display: none;
    }
  }

  &.hoverable {
    transition: box-shadow $transition-hover-out;

    &:hover {
      @include shadow-6;

      transition: box-shadow $transition-hover-in;
    }

    &:active {
      @include shadow-2;

      transition: box-shadow $transition-hover-in;
    }
  }

  &.list {
    width: 100%;
    margin-bottom: 2rem;

    @include flexbox;
    align-items: flex-start;

    .card-image {
      max-width: 50rem;
      min-width: 10rem;
      width: 35%;
      //margin: 1rem;

      border-radius: $border-radius 0 0 $border-radius;
    }

    .card-content {
      padding: 1rem 2rem 1rem 1rem;
    }

    &[data-label] {
      &::before {
        top: 1.5rem;
        left: 1.5rem;
      }
    }

    &[data-count] {
      &::after {
        top: auto;
        bottom: 1rem;
      }

      .card-content {
        padding-bottom: 5rem;
      }
    }
  }
}

.card-image {
  width: 100%;

  border-radius: $border-radius $border-radius 0 0;
}

.card-content {
  padding: 1rem 1.5rem;

  //color: #FFF;

  //background-color: $primary-colour;
}

.card-title {
  padding-bottom: 0.75rem;

  font-weight: 700;
}

.card-title,
.card-subtitle {
  ~ .card-meta {
    padding-top: 0 !important;
  }
}

.card-subtitle {
  font-weight: 400;
}

.card-meta {
  display: block;

  //font-size: 1.75rem;
}

a.card {
  color: $base-font-colour;
  text-decoration: none;
}
