.loader {
  &.inline {
    margin: 4rem auto;

    position: relative;
    top: 0;
    left: 0;

    box-shadow: none;
  }
}

.photo-loader {
  width: 100%;
  padding-bottom: (2 / 3) * 100%;

  display: block;

  @include animated-background;

  &.aspect-16-9 {
    padding-bottom: (9 / 16) * 100%;
  }

  @for $i from 1 through 12 {
    &.animation-offset-#{$i} {
      animation-delay: ($i - 1) * 100ms;
    }
  }
}
