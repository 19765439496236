* {
  margin: 0;
  padding: 0;
}

html {
  min-width: 320px;
  width: 100%;
  height: 100%;

  overflow: auto;

  font-size: 8px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  width: 100%;
  min-height: 100%;

  color: $base-font-colour;
  font-family: $base-font-family;
  font-size: $base-font-size-large;
  line-height: $base-line-height-large;
  font-weight: $base-font-weight;

  @media (max-width: $largest-small-device) {
    font-size: $base-font-size-mobile;
    line-height: $base-line-height-mobile;
  }
}

img,
svg,
video {
  max-width: 100%;

  display: block;

  @include flex(none);

  &.align-center {
    margin-right: auto;
    margin-left: auto;
  }
}

a {
  color: $primary-colour;

  &:hover {
    color: $primary-colour;
  }
}

input,
select,
textarea,
button {
  font-family: $base-font-family;
  font-size: $base-font-size-large;
  line-height: $base-line-height-large;
}

label,
input,
select,
textarea {
  width: 100%;
  max-width: 100%;

  box-sizing: border-box;

  font-size: 2rem;
  line-height: 1.25;
}

input,
select,
textarea {
  padding: 1rem 1.5rem;

  background-color: #FFF;

  border: 1px #D3D3D3 solid;
  border-radius: $border-radius;
  outline: none !important;

  //box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.08);
}

label.material-icons {
  width: auto;

  line-height: 1;

  border: none;
}

input {
  min-width: 128px;

  color: inherit;

  -webkit-appearance: none;

  &[type="checkbox"] {
    -webkit-appearance: checkbox;
  }

  &[type="radio"] {
    -webkit-appearance: radio;
  }

  &[type="checkbox"],
  &[type="radio"] {
    min-width: 0;
    width: auto;
    padding: 0;

    border: none;
  }
}

input,
textarea {
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.35);
  }

  &::-moz-placeholder {
    color: rgba(0, 0, 0, 0.35);
  }

  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.35);
  }

  &:-moz-placeholder {
    color: rgba(0, 0, 0, 0.35);
  }

  &:disabled,
  &:read-only {
    color: rgba(0, 0, 0, 0.5);
  }
}

select {
  height: 4.75rem;
  padding-top: 0;
  padding-bottom: 0;

  -webkit-appearance: none;
}

textarea {
  display: block;

  resize: vertical;
}

button {
  //height: 3em;
  //padding: 0 2em;

  //position: relative;

  //color: #FFF;
  font-family: $base-font-family;
  font-size: $base-font-size-large;

  //background-color: $base-font-colour;

  border: none;
  outline: none;

  //transition: background-color $transition-hover-out;

  cursor: pointer;

  &:hover {
    //background-color: $primary-colour;

    //transition: background-color 100ms;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

code {
  outline: 1px #CCC solid;
}

.material-icons {
  vertical-align: middle;

  + * {
    vertical-align: middle;
  }
}
