.container {
  max-width: $largest-width;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: ($gutter / 2);
  padding-left: ($gutter / 2);

  box-sizing: border-box;

  @include flexbox;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;

  &.no-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  &.cosy {
    max-width: calc(#{$largest-width} - #{$gutter + 2rem});
    padding-right: ($gutter / 8);
    padding-left: ($gutter / 8);

    .column {
      padding-right: ($gutter / 8);
      padding-left: ($gutter / 8);
    }
  }
}

.column {
  padding-right: ($gutter / 2);
  padding-left: ($gutter / 2);

  box-sizing: border-box;

  &.no-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  &.cosy {
    padding-right: ($gutter / 8);
    padding-left: ($gutter / 8);
  }

  &.full {
    width: 100% !important;

    &:not(:last-child) {
      padding-bottom: 0;
    }
  }
}

.flex-auto {
  @include flex(1 1 auto);
}

.width-full {
  width: 100%;
}

.flex-0 {
  @include flex(none);
}

@for $i from 1 through 3 {
  .flex-#{$i} {
    @include flex($i 0);
  }
}

@for $i from 1 through 5 {
  .flex-order-#{$i} {
    @include flex-order($i);
  }
}

@for $i from 2 through 6 {
  .width-#{floor(100 / $i)} {
    //width: (100% / $i);
  }

  @for $j from 1 through $i - 1 {
    .width-#{$j}-#{$i} {
      width: ((100% / $i) * $j);
    }
  }
}

@for $i from 1 through 10 {
  .width-#{$i * 5}rem {
    width: #{$i * 5}rem;
  }
}
