












































































































































@import "../styles/env";

.welcome {
  margin-bottom: 4rem;

  p {
    .material-icons {
      margin-right: 1rem;
    }
  }
}

.sign-in-form-wrapper {
  /*flex-basis: 728px;*/
}

.sign-in-form {
  padding: 3rem;

  position: relative;

  overflow: hidden;

  background-color: #F9F9F9;

  border-radius: $border-radius;

  @include shadow-2;

  h2 {
    margin: -3rem -3rem 3rem -3rem;
    padding: 2rem 3rem;

    color: #FFF;

    background-color: $primary-colour;
  }

  input {
    display: block;

    background-color: #FFF;
  }

  .sign-in-email {
    height: 6rem;

    overflow: hidden;

    line-height: 6rem;

    background-color: #FFF;

    border-radius: $border-radius;

    @include shadow-2;

    .material-icons {
      padding: 0 2rem;

      line-height: inherit;
    }

    input,
    button {
      display: block;

      border: none;
    }

    input {
      font-size: 2.5rem;
    }

    button {
      padding: 0;

      color: inherit;
      font-size: 2.5rem;

      background-color: transparent;

      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.invalid-email-warning {
  margin-top: 2rem;
}

.success,
.error {
  margin-top: 2rem;
}
