.form {
  //margin-bottom: 4rem;
  padding: 3rem;

  position: relative;

  overflow: hidden;

  background-color: #F9F9F9;

  border-radius: $border-radius;

  @include shadow-2;

  h1 {
    margin: -3rem -3rem 3rem -3rem;
    padding: 2rem 3rem;

    color: #FFF;

    background-color: $primary-colour;
  }

  h5 {
    padding-bottom: 1rem;
  }

  label,
  input,
  select,
  textarea {
    display: block;
  }

  label {
    margin-bottom: 0.5rem;

    font-weight: 700;

    cursor: pointer;

    input {
      &[type="checkbox"],
      &[type="radio"] {
        margin-right: 2rem;
      }
    }

    small,
    em {
      font-weight: 400;
    }
  }

  img,
  input,
  select,
  textarea {
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      margin: 0;

      display: inline-block;

      background-color: transparent;
    }
  }

  button {
    margin: 3rem 0 0;

    font-weight: 700;
    text-transform: uppercase;
  }

  #g-recaptcha {
    margin-right: 3rem;
  }

  .loader-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background-color: rgba(255, 255, 255, 0.85);

    .loader {
      box-shadow: none;
    }
  }
}
