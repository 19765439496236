




































@import "../styles/env";

.pagination {
  button {
    padding: 1rem 0;

    white-space: nowrap;

    background-color: transparent;

    &:not(:disabled):hover {
      color: $primary-colour;
    }

    &.current {
      color: #FFF;
      font-weight: 700;

      background-color: $primary-colour;

      &:hover {
        color: #FFF;
      }
    }

    &:disabled {
      opacity: 0.35;
    }

    span {
      vertical-align: middle;
    }
  }
}
