



















































@import "../../styles/env";

.gallery-item-photo {
  width: 100%;

  @for $i from 1 through 12 {
    &.animation-offset-#{$i} {
      a {
        &:before {
          animation-delay: ($i - 1) * 100ms;
        }
      }
    }
  }
}

a {
  height: 0;

  display: block;

  position: relative;

  &:before {
    display: block;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background: linear-gradient(-45deg, #EEE, #BCBCBC, #EEE);
    background-size: 400% 400%;

    animation: slide-background ease 1.2s infinite;

    content: "";
  }

  &.loaded {
    height: auto;
    padding-bottom: 0 !important;

    &:before {
      display: none;
    }
  }
}

img {
  width: 100%;
}
