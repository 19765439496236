






































































































































@import "../styles/env";

.favourites {
  .column {
    margin-bottom: 5rem;
  }
}

.item-wrapper {
  overflow: hidden;

  border-radius: $border-radius;

  @include shadow-2;
}

.card {
  margin-bottom: 0;

  border-radius: 0;

  z-index: 1;
}

.links {
  position: relative;
  z-index: 2;

  //background-color: $primary-colour;
  background-color: #FFF;

  border-top: 1px rgba($base-font-colour, 0.1) solid;

  border-radius: 0 0 $border-radius $border-radius;

  a,
  button {
    /*width: 100%;*/
    margin: 0;
    padding: 1.5rem 2rem 1rem;

    @include flex(1);

    color: $base-font-colour;
    text-align: center;
    font-size: 1.75rem;

    background-color: transparent;

    border-radius: 0;

    box-shadow: none;

    .material-icons {
      margin-bottom: 0.5rem;

      display: block;

      color: $primary-colour;

      transition: inherit;
    }

    &:hover {
      color: #FFF;

      .material-icons {
        color: inherit;
      }
    }
  }

  .divider {
    width: 1px;

    background-color: rgba($base-font-colour, 0.1);
  }
}
