[class^="width-"],
[class*=" width-"] {
  //@include flex(none);
}

.flex-auto-medium {
  @include flex(1 1 auto);
}

.width-full-medium {
  width: 100%;
}

.flex-0-medium {
  @include flex(none);
}

@for $i from 1 through 3 {
  .flex-#{$i}-medium {
    @include flex($i 0);
  }
}

@for $i from 2 through 5 {
  .width-#{floor(100 / $i)}-medium {
    width: (100% / $i);
  }

  @for $j from 1 through $i - 1 {
    .width-#{$j}-#{$i}-medium {
      width: ((100% / $i) * $j);
    }
  }
}
