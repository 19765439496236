










































































































































@import "../../styles/env";

.share-wrapper {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.85)
}

.share {
  max-width: 100%;
  /*width: 500px;*/
  max-height: 100%;
  /*height: 500px;*/

  /*background-color: #FFF;*/

  a {
    margin: 3rem;

    display: block;

    &.facebook {
      background-color: $facebook;
    }

    &.twitter {
      background-color: $twitter;
    }

    &.email {
      background-color: #424242;
    }

    i {
      margin-right: 3rem;
    }
  }
}

.red-heart {
  width: 100%;

  position: relative;

  color: $favourite-colour;

  &::before {
    width: 14rem;
    height: 14rem;
    margin: -7rem 0 0 -7rem;

    position: absolute;
    top: 50%;
    left: 50%;

    background-color: $favourite-colour;

    border-radius: 100%;

    transform: scale(0);

    animation: echo ease-out 600ms;

    opacity: 0;

    content: "";
  }
}

.favourite-identifier {
  width: 15rem;
  height: 15rem;

  position: fixed;
  top: 50%;
  left: 50%;

  color: $favourite-colour;
  font-size: 15rem;

  transform: translateX(-50%) translateY(-50%);

  .material-icons {
    display: block;

    font-size: inherit;
  }
}

.pswp__caption__center {
  text-align: center;
}
