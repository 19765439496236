@keyframes slide-background {
  0%,
  100% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes echo {
  0% {
    transform: scale(0);

    opacity: 1;
  }

  100% {
    transform: scale(1);

    opacity: 0;
  }
}

@for $i from 1 through 24 {
  .animation-offset-#{$i} {
    animation-delay: ($i - 1) * 25ms;
  }
}

.animate-zoom-in {
  transform: scale(0);
  animation-name: zoom-in;
  animation-timing-function: ease-out;
  animation-duration: $transition-hover-in;
  animation-fill-mode: forwards;
}
