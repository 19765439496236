/*.container {
  &:not(.columns-on-small):not(.columns-on-tiny) {
    .column,
    [class^="flex-"],
    [class*=" flex-"] {
      width: 100%;

      @include flex(none);
      @include flex-order(0);
    }
  }
}*/

.flex-auto-small {
  @include flex(1 1 auto);
}

.width-full-small {
  width: 100%;
}

.flex-0-small {
  @include flex(none);
}

@for $i from 1 through 3 {
  .flex-#{$i}-small {
    @include flex($i 0);
  }
}

@for $i from 2 through 4 {
  .width-#{floor(100 / $i)}-small {
    width: (100% / $i);
  }

  @for $j from 1 through $i - 1 {
    .width-#{$j}-#{$i}-small {
      width: ((100% / $i) * $j);
    }
  }
}
