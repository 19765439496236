@mixin flexbox-core() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

@mixin flexbox-core-important() {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
}

@mixin flexbox() {
  @include flexbox-core;
}

@mixin inline-flexbox() {
  //display: -moz-inline-flex;
  display: -webkit-inline-flex;
  display: inline-flex;
}

@mixin inline-flexbox-important() {
  //display: -moz-inline-flex !important;
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

@mixin flexbox-vertical() {
  @include flexbox-core;
  -ms-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flexbox-vertical-important() {
  @include flexbox-core;
  -ms-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin flex-order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin shadow-0 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.12);
}

@mixin shadow-1 {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
}

@mixin shadow-2 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@mixin shadow-2-inset {
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.14), inset 0 3px 1px -2px rgba(0, 0, 0, 0.2), inset 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@mixin shadow-3 {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

@mixin shadow-4 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

@mixin shadow-6 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

@mixin shadow-8 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

@mixin shadow-16 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

@mixin shadow-24 {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
}

@mixin primary-grad-background {
  background-color: $primary-colour;
  background: -moz-linear-gradient(top, $primary-colour 0%, #303F9F 100%);
  background: -webkit-linear-gradient(top, $primary-colour 0%, #303F9F 100%);
  background: linear-gradient(to bottom, $primary-colour 0%, #303F9F 100%);
}

@mixin animated-background {
  background: linear-gradient(-45deg, #EEE, #BCBCBC, #EEE);
  background-size: 400% 400%;
  background-position: 0 50%;

  animation: slide-background ease 1.2s infinite;
}
