.page {
  min-height: 100%;

  .standalone & {
    background-color: #FFF;
  }
}

main {
  padding-top: 5rem;
  padding-bottom: 5rem;

  background-color: #FFF;

  @media #{$media-mobile} {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

footer {
  .social {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    a {
      text-decoration: none;
      white-space: nowrap;

      .fab {
        margin-right: 0.5rem;
      }

      &:hover {
        .fab {
          transform: translateY(-2px);
        }
      }
    }
  }

  hr {
    background-color: rgba(255, 255, 255, 0.15);
  }
}
