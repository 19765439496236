





















































































@import "../styles/env";

$dropdown-width: 35rem;

nav {
  /*padding: 0.5rem 0;*/

  position: sticky;
  top: 0;
  z-index: 5;

  background-color: #212121;

  @include shadow-4;

  > .container {
    height: 7rem;
  }
}

a {
  height: 6rem;
  margin-left: 2.5rem;

  color: #FFF;
  text-decoration: none;

  @include flexbox;
  align-items: center;

  @media #{$media-small} {
    /*margin-left: 0;*/
  }

  &:last-child {
    /*margin-right: 0;*/
  }

  &:hover {
    color: #FFF;
  }

  /*&.boxed {
    padding: 0.5rem 1rem;

    border: 1px rgba(#FFF, 0.5) solid;
    border-radius: $border-radius;

    &:hover {
      border-color: #FFF;
    }
  }*/

  i,
  span {
    margin-left: 1rem;

    display: inline-block;
  }
}

.logo {
  margin-left: 0;

  font-size: 1.5rem;

  img {
    display: inline-block;

    vertical-align: bottom;
  }

  span {
    /*opacity: 0;*/
  }

  &:hover {
    span {
      /*opacity: 1;*/
    }
  }
}

.links {
  > a {
    padding: 0.5rem 1rem;

    position: relative;

    overflow: hidden;

    &:before {
      height: 0.5rem;

      position: absolute;
      top: 100%;
      right: 0;
      left: 0;

      background-color: $primary-colour;

      border-radius: 0.5rem 0.5rem 0 0;

      transition: transform $transition-hover-in;

      content: "";
    }

    &.router-link-active {
      &:before {
        transform: translateY(-100%);

        transition-delay: $transition-hover-in;
      }
    }
  }
}

.links-mask {
  width: 100vw;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  background-color: rgba(#000, 0);

  transform: translateX(100vw);

  transition: transform 1ms $transition-animate, background-color ($transition-animate - 1ms);

  &.show {
    background-color: rgba(#000, 0.75);

    transform: translateX(0);

    transition: transform 1ms, background-color ($transition-animate - 1ms) 1ms;
  }
}

.dropdown-container {
  margin-left: 2.5rem;

  position: relative;
}

.toggle-dropdown {
  display: block;

  position: relative;

  color: #FFF;

  background-color: transparent;

  &::before,
  &::after {
    width: 2px;
    height: 70%;

    position: absolute;
    top: 50%;
    left: 2rem;
    z-index: -1;

    background-color: #FFF;

    /*opacity: 0;*/

    transition: transform $transition-animate;

    content: "";
  }

  &::before {
    transform: translateY(-50%) rotate(0) scale(0);
  }

  &::after {
    transform: translateY(-50%) rotate(0) scale(0);
  }

  .material-icons {
    display: inline-block;

    transition: opacity $transition-animate;
  }
}

.dropdown {
  width: 0;
  max-height: 0;

  position: absolute;
  top: 6rem;
  right: 1.5rem;

  overflow: hidden;

  direction: rtl;

  background-color: #FFF;

  @include shadow-8;

  border-radius: $border-radius;

  transition: width $transition-animate, max-height $transition-animate;
  transition-timing-function: cubic-bezier(.25, .8, .25, 1);
  /*transition: all 0.3s cubic-bezier(.25,.8,.25,1);*/

  /*opacity: 0;*/

  > div,
  > a,
  > button {
    width: $dropdown-width;
    height: auto;
    margin: 0;
    padding: 1rem 2rem;

    direction: ltr;

    box-sizing: border-box;
  }

  a,
  button {
    height: 6rem;

    color: $base-font-colour;
    /*color: #FFF;*/

    border-top: 1px rgba(0, 0, 0, 0.15) solid;
  }

  button {
    text-align: left;

    .material-icons {
      margin-left: 1rem;

      display: inline-block;
    }
  }

  &.show {
    width: $dropdown-width;
    max-height: 23rem;

    transition: width $transition-animate, max-height $transition-animate;

    /*opacity: 1;*/

    ~ .toggle-dropdown {
      &::before,
      &::after {
        opacity: 1;
      }

      &::before {
        transform: translateY(-50%) rotate(45deg) scale(1);
      }

      &::after {
        transform: translateY(-50%) rotate(-45deg) scale(1);
      }

      .profile-photo {
        transform: translateY(6.5rem) translateX(-0.5rem) scale(1.75);
      }

      .material-icons {
        opacity: 0;
      }
    }
  }
}

.profile-photo {
  display: inline-block;

  overflow: hidden;

  vertical-align: middle;

  border-radius: 100%;

  @include shadow-2;

  /*transform: translateY(0) scale(1);*/

  transition: transform $transition-hover-in;
}

.user {
  /*padding: 0.5rem 1rem 0.5rem 2rem;*/
}

.user-name {
  padding-right: 7rem;

  font-weight: 700;
}

.toggle-sidebar {
  width: 6rem;
  height: 6rem;
  padding: 1rem;

  display: none;

  color: #FFF;

  background: none;
}

.add-to-home-screen-wrapper {
  padding: 2rem 3rem;

  border-top: 1px rgba(0, 0, 0, 0.15) solid;
}

.add-to-home-screen {
  width: 100%;
}

@media #{$media-tiny} {
  .links,
  .dropdown {
    > a {
      margin: 0;
      padding: 0 3rem;

      position: relative;

      &:before {
        width: 1rem;
        height: auto;

        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: auto;

        background-color: $primary-colour;

        border-radius: 0;

        transition: transform $transition-hover-in;

        content: "";
      }

      &.router-link-active {
        &:before {
          transform: translateX(100%);

          transition-delay: $transition-hover-in;
        }
      }

      &.sign-in-link {
        margin-top: 5rem;
      }

      &.social {
        i {
          margin-left: 0;
          margin-right: 2rem;

          &.fa-facebook {
            color: $facebook;
          }

          &.fa-twitter {
            color: $twitter;
          }

          &.fa-instagram {
            background: -webkit-linear-gradient(#feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.fa-skype {
            color: $skype;
          }
        }
      }
    }
  }

  .links {
    width: 80vw;

    display: block !important;
    /*flex-direction: column;*/

    overflow: auto;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    z-index: 11;

    /*background-color: #212121;*/
    background-color: #FFF;

    box-shadow: none;

    transition: box-shadow $transition-animate, transform $transition-animate;

    a {
      color: $base-font-colour;

      background-color: #FFF;
    }

    &.show {
      @include shadow-16;

      transform: translateX(-100%);

      &::before {
        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75);
      }
    }

    a {
      border-top: 1px rgba(0, 0, 0, 0.15) solid;
    }
  }

  .close-sidebar {
    width: 3rem;
    height: 3rem;

    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
  }

  .dropdown-container {
    margin: 0;
  }

  .dropdown {
    width: auto !important;
    max-height: none !important;
    height: auto;
    margin-top: 5rem;

    position: static;

    background-color: transparent;

    border-top: 1px rgba(0, 0, 0, 0.15) solid;
    border-radius: 0;

    box-shadow: none;

    > div,
    > a {
      width: auto;
    }

    a {
      height: 6rem;
      margin: 0;
      padding: 0 3rem;

      /*color: #FFF;*/
    }
  }

  .user-name {
    padding: 1rem 0 1rem 6rem;
  }

  .toggle-dropdown {
    position: absolute;
    top: 1rem;
    left: 2rem;

    ::before,
    ::after {
      display: none;
    }

    .profile-photo {
      transform: none !important;

      box-shadow: none;

      img {
        width: 5rem;
        height: 5rem;
      }
    }

    .material-icons {
      display: none;
    }
  }

  .toggle-sidebar {
    display: block;
  }
}
