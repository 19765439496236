















































@import "../styles/env";

.notification-wrapper {
  &.dismissed {
    transform: translateX(100vw);

    transition: transform 1ms $transition-animate;

    .notification {
      transform: translateX(100vw);
    }
  }
}

.notification {
  margin-top: 1rem;
  padding: 2rem 2rem 2rem 3rem;

  position: relative;

  overflow: hidden;

  background-color: #FFF;

  border-radius: $border-radius;

  @include shadow-6;

  transition: transform $transition-animate ease-in;

  &::before {
    width: 1rem;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    content: "";
  }

  &.error {
    &::before {
      background-color: #C00;
    }
  }
}

.lifetime-wrapper {
  height: 0.5rem;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.lifetime {
  width: 0;
  height: 100%;

  background-color: $primary-colour;

  animation-name: lifetime;
  animation-timing-function: linear;
  animation-duration: $transition-hover-in;
  animation-fill-mode: forwards;
}

.notification-body {
  margin-right: 2rem;

  p {
    padding-bottom: 0.5rem;
  }
}

@keyframes lifetime {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}
