































@import "../styles/env";

.search-bar {
  padding-top: ($gutter / 2);
  padding-bottom: ($gutter / 2);
}

.search-form {
  height: 6rem;

  overflow: hidden;

  color: #FFF;
  line-height: 6rem;

  background-color: rgba(255, 255, 255, 0.15);

  border-radius: $border-radius;

  @include shadow-2;

  .material-icons {
    padding: 0 2rem;

    line-height: inherit;
  }

  label {
    color: rgba(255, 255, 255, 0.75);
  }

  input,
  button {
    display: block;

    background-color: transparent;

    border: none;
  }

  input {
    font-size: 2.5rem;

    border-radius: 0;

    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.25);
    }

    &::-moz-placeholder {
      color: rgba(255, 255, 255, 0.25);
    }

    &:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.25);
    }

    &:-moz-placeholder {
      color: rgba(255, 255, 255, 0.25);
    }
  }

  button {
    padding: 0;

    color: inherit;
    font-size: 2.5rem;

    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }
}
