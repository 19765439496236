.container {
  padding-right: ($gutter-mobile / 2);
  padding-left: ($gutter-mobile / 2);
}

.column {
  padding-right: ($gutter-mobile / 2);
  padding-left: ($gutter-mobile / 2);
}

.flex-auto-mobile {
  @include flex(1 1 auto);
}

.width-full-mobile {
  width: 100%;
}

.flex-0-mobile {
  @include flex(none);
}

@for $i from 1 through 3 {
  .flex-#{$i}-mobile {
    @include flex($i 0);
  }
}

@for $i from 2 through 3 {
  .width-#{floor(100 / $i)}-mobile {
    //width: (100% / $i);
  }

  @for $j from 1 through $i - 1 {
    .width-#{$j}-#{$i}-mobile {
      width: ((100% / $i) * $j);
    }
  }
}

.block-on-mobile {
  display: block !important;
}
