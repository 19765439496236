





























































@import "../styles/env";

.notices {
  position: fixed;
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem;
  z-index: 11;

  @media #{$media-mobile} {
    top: 3rem;
    right: 3rem;
    left: 3rem;
  }
}

.notices-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background-color: rgba(0, 0, 0, 0.75);
}
