











































































@import "../styles/env";

.photo {
  width: 20vw;
  max-width: 300px;

  position: absolute;
  right: -100%;

  border: 1rem #FFF solid;
  border-bottom-width: 4rem;
  border-radius: $border-radius;

  @include shadow-4;

  // transform: translateY(-50%) rotate(0) scale(0) translateX(150%);
  transform: scale(0) translate3d(150%, -50%, 0) rotate(0);

  transition: transform 400ms;

  &::before,
  &::after {
    position: absolute;
    top: 100%;

    overflow: hidden;

    color: rgba(0, 0, 0, 0.75);
    line-height: 4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &::before {
    right: 40%;
    left: 0;

    font-size: 1.5rem;
    text-align: left;

    content: attr(data-title);
  }

  &::after {
    right: 0;
    left: 60%;

    font-size: 1.25rem;
    text-align: right;

    content: attr(data-date);
  }

  &.loaded {
    right: 0;
  }

  img {
    width: 100%;
  }
}
