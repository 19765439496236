











































@import "../styles/env";

.notifications {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  left: 2rem;
  z-index: 21;

  transform: translateY(100vh);

  transition: transform 1ms $transition-animate;

  &.show {
    transform: translateY(0);

    transition: transform 1ms;
  }

  @media #{$media-mobile} {
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
}
