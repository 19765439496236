.sidebar {
  text-align: right;

  h4,
  a {
    padding: 2rem 3rem;

    display: block;

    position: relative;

    color: #FFF;

    &::after {
      height: 1px;

      display: block;

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.35) 90%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.35) 90%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.35) 90%);

      content: "";
    }
  }

  a {
    text-decoration: none;

    border-right: 0 rgba(255, 255, 255, 0.35) solid;

    transition: border-right-width 200ms;

    @media #{$media-mobile} {
      transition: none;
    }

    &.router-link-exact-active {
      border-right-width: 1rem;
    }
  }

  h4 {
    margin-top: 5rem;

    font-size: 1.75rem;
    text-transform: uppercase;
  }
}
