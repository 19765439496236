@import "../../../node_modules/material-design-lite/src/progress/_progress.scss";

.firebaseui-idp-list {
  padding: 0;

  list-style: none;

  @include flexbox;
  justify-content: space-evenly;

  @media #{$media-tiny} {
    display: block;
  }
}

.firebaseui-list-item {
  //width: 35rem;
  padding: 0;

  + .firebaseui-list-item {
    margin-left: 2rem;

    @media #{$media-tiny} {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}

.firebaseui-idp-button {
  width: 100%;
  min-height: 9rem;
  padding: 2rem 3rem 2rem 10rem;

  display: block;

  position: relative;

  text-align: left;
  line-height: 1.5;

  border-radius: $border-radius;

  @include flex(1);

  @include shadow-2;

  &.firebaseui-idp-facebook {
    color: #FFF;

    background-color: $facebook;
  }

  &.firebaseui-idp-google {
    background-color: #F5F5F5;
  }

  .firebaseui-idp-icon-wrapper {
    width: 10rem;

    @include flexbox;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    .firebaseui-idp-icon {
      width: 5rem;
      margin: 0 auto;
    }
  }

  .firebaseui-idp-text {
    display: block;

    &.firebaseui-idp-text-long {
    }

    &.firebaseui-idp-text-short {
      display: none;
    }
  }
}

.sign-in-form {
  //.mdl-card {
  //  width: 100%;
  //  min-height: 0;
  //}

  .mdl-progress {
    width: 100%;
  }
}
