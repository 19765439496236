






















































@import "../styles/env";

.sub-header {
  padding-top: 1rem;
  padding-bottom: 1rem;

  position: sticky;
  top: 0;
  z-index: 2;

  @include shadow-4;

  background-color: $primary-colour;

  > .container {
    min-height: 4rem;
  }
}

.backlink {
  color: #FFF;
  //color: $base-font-colour;

  display: block;

  text-decoration: none;
  line-height: 4rem;

  .material-icons {
    margin-right: 1rem;

    color: $primary-colour-really-light;

    transition: color $transition-hover-out;
  }

  &:hover {
    .material-icons {
      color: #FFF;

      transition: color $transition-hover-in;
    }
  }
}

.buttons {
  button {
    width: 4rem;
    height: 4rem;

    color: #FFF;

    &.inactive {
      opacity: 0.35;

      transition: opacity $transition-animate;
    }
  }
}
