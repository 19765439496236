hr {
  height: 1px;
  margin: 3rem 0;

  display: block;

  clear: both;

  background-color: #BDBDBD;

  border: none;

  //&[data-title] {
  //  &::before {
  //    content: attr(data-title);
  //  }
  //}
}
