a,
button {
  &.block {
    margin: 0 auto;
    padding: 1.5rem 3rem;

    display: inline-block;

    box-sizing: border-box;

    color: #FFF;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    white-space: nowrap;

    background-color: $primary-colour;

    border-radius: $border-radius;

    @include shadow-2;

    transition: color $transition-hover-out, background-color $transition-hover-out, box-shadow $transition-hover-out;

    &.large {
      font-size: 2.5rem;
    }

    &.full {
      width: 100%;
    }

    &.thin {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &.tiny {
      padding: 0 1.5rem;
    }

    &:not(:disabled):hover {
      background-color: $base-font-colour;

      @include shadow-4;

      transition: color $transition-hover-in, background-color $transition-hover-in, box-shadow $transition-hover-in;
    }

    &:not(:disabled):active {
      @include shadow-1;

      transition: color $transition-hover-in, background-color $transition-hover-in, box-shadow $transition-hover-in;
    }

    &.inverted {
      color: $base-font-colour;

      background-color: #FFF;

      &:not(:disabled):hover {
        background-color: #EEE;
      }
    }

    &:disabled {
      opacity: 0.35;
    }
  }

  &.big-simple {
    display: block;

    background-color: transparent;

    opacity: 0.65;

    &:not(:disabled):hover {
      opacity: 1;
    }

    &.inverted {
      color: #FFF;
    }
  }

  .material-icons {
    line-height: 1;
    text-decoration: none;

    &.inline {
      margin-right: 1rem;
    }
  }

  &.ui-good {
    background-color: $ui-good-colour;
  }

  &.ui-bad {
    background-color: $ui-bad-colour;
  }
}

button {
  background-color: transparent;

  border: none;

  .material-icons {
    display: block;

    &.inline {
      display: inline-block;
    }
  }
}
