

























































































































































































































@import "../../styles/env";

.album-title-wrapper {
  margin-bottom: 2rem;

  .standalone & {
    margin-bottom: 0;
  }
}

.album-title {
  .standalone & {
    padding-bottom: 0;
  }
}

.album-parent-title {
  color: rgba(255, 255, 255, 0.75);
  font-size: 3rem;
  font-weight: 400;
}

main {
  transition: padding-top 200ms;

  @media (max-width: #{$largest-width}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.error {
  //color: #FFF;
}

.date-separator {
  margin-top: 1rem;
  margin-bottom: 1rem;

  font-size: 2rem;
  text-align: right;

  border-bottom: 1px $base-font-colour solid;
}

.gallery-photo {
  margin-bottom: $gutter / 4;

  @include flexbox;
  justify-content: center;
  align-items: center;
}

.photo-placeholder {
  &:before {
    padding-bottom: (2 / 3) * 100%;

    display: block;

    background: linear-gradient(-45deg, #EEE, #BCBCBC, #EEE);
    background-size: 400% 400%;

    animation: slide-background ease 1.2s infinite;

    content: "";
  }

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      &:before {
        animation-delay: ($i - 1) * 100ms;
      }
    }
  }
}

.advert {
  width: 100%;
  margin-bottom: 1rem;
}

.loading-pad {
  width: 100%;
  height: 200px;

  display: block;
}
