

































































































































































@import "../../styles/env";

.album-access-management {
  max-height: 50rem;
  padding-top: 4rem;

  overflow: auto;

  background-color: #FFF;

  transition: padding-top 200ms;

  @media (max-width: #{$largest-width}) {
    padding-top: 1rem;
  }
}
