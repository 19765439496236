table {
  width: 100%;

  border-collapse: collapse;
}

thead {
  color: #FFF;

  background-color: $primary-colour;
}

tbody {
  tr {
    &:nth-child(even) {
      background-color: $primary-highlight-1-light;
    }
  }
}

th,
td {
  padding: 0.5rem 1rem;

  text-align: left;

  button.block {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    + button.block {
      margin-left: 1rem;
    }
  }
}
