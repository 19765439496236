.header {
  padding-top: 5rem;
  padding-bottom: 5rem;

  position: relative;

  color: #FFF;

  background: url("/images/backgrounds/home.jpg") center/cover;

  .standalone & {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    z-index: 3;

    background: $primary-colour;
    //@include primary-grad-background;

    //@include shadow-2-inset;

    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background-color: rgba(0, 0, 0, 0.5);

    content: "";
  }

  > * {
    position: relative;
    z-index: 2;
  }

  h1 {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);

    &:last-child {
      padding-bottom: 0;
    }
  }

  a {
    color: #FFF;
  }

  .backlink {
    display: inline-block;

    text-decoration: none;

    .standalone & {
      margin-bottom: 1rem;
    }

    .material-icons {
      margin-right: 1rem;

      opacity: 0.5;
    }

    &:hover {
      .material-icons {
        opacity: 1;
      }
    }
  }

  .search-form {
    margin-top: $gutter;
    margin-bottom: $gutter / 2;

    .standalone & {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
