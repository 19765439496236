.pswp {
  z-index: 20;
}

.pswp__ui {
  button,
  a {
    color: #FFF;
  }
}

.pswp--fs {
  .pswp__bg {
    opacity: 1 !important;
  }
}

.pswp__button {
  text-align: center;
  line-height: 44px;

  background: none !important;

  //&.pswp__button--download,
  //&.pswp__button--report,
  //&.pswp__button--favourite,
  //&.pswp__button--share {
  //  background-image: url("../images/photoswipe/custom.png");
  //  background-size: 132px 88px;
  //
  //  @media (-webkit-min-device-pixel-ratio: 1.1), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  //    .pswp--svg & {
  //      background-image: url("../images/photoswipe/custom.svg");
  //    }
  //  }
  //}
  //
  //&.pswp__button--download {
  //  background-position: 0 0;
  //}
  //
  //&.pswp__button--report {
  //  background-position: -44px 0;
  //}
  //
  //&.pswp__button--share {
  //  background-position: -88px 0;
  //}
  //
  //&.pswp__button--favourite {
  //  background-position: 0 -44px;
  //  //background-position: -44px -44px;
  //}
}

.hide-on-pswp-no-fs {
  display: none !important;
}

.pswp--fs {
  .hide-on-pswp-fs {
    display: none !important;
  }

  .hide-on-pswp-no-fs {
    display: block !important;
  }
}

//.pswp__share-tooltip {
//  a {
//    &.pswp__share--facebook {
//      &:hover {
//        &::before {
//          border-bottom-color: #FFF;
//        }
//      }
//    }
//
//    &.pswp__share--download,
//    &.pswp__share--report {
//      &:hover {
//        background-color: #F09;
//      }
//    }
//
//    .fa {
//      vertical-align: baseline;
//    }
//  }
//}
