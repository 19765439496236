









































































































































































































@import "../../styles/env";

.venue-title {
  margin-bottom: 2rem;

  .standalone & {
    margin-bottom: 0;
  }
}

.subscription-button {
  &:disabled {
    opacity: 0.5;
  }
}
