



































footer {
  padding-top: 5rem;

  color: #FFF;
  //font-size: 1.75rem;

  a {
    //display: block;

    color: #FFF;
  }
}

.container {
  padding-bottom: 3rem;
}
