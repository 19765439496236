


















































@import "./styles/global";

#app {
  min-height: 100vh;
}
