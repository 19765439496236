.flexbox {
  @include flexbox-core-important;
}

.flexbox-vertical {
  @include flexbox-vertical-important;
}

.inline-flexbox {
  @include inline-flexbox-important;
}

.flexbox-wrap {
  flex-wrap: wrap !important;
}

.flexbox-nowrap {
  flex-wrap: nowrap !important;
}

.flexbox-align-start {
  align-items: flex-start !important;
}

.flexbox-align-center {
  align-items: center !important;
}

.flexbox-align-end {
  align-items: flex-end !important;
}

.flexbox-align-stretch {
  align-items: stretch !important;
}

.flexbox-space-between {
  justify-content: space-between !important;
}

.flexbox-justify-center {
  justify-content: center !important;
}

.flexbox-justify-stretch {
  justify-content: stretch !important;
}

.flexbox-justify-end {
  justify-content: flex-end !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.white-text-colour {
  color: #FFF !important;
}

.primary-text-colour {
  color: $primary-colour !important;
}

.primary-background-colour {
  background-color: $primary-colour !important;
}

.primary-background-colour-grad {
  @include primary-grad-background;
}

.white-background-colour {
  background-color: #FFF !important;
}

.transition-background-colour {
  transition: background-color $transition-hover-out;

  &:hover {
    transition: background-color 100ms;
  }
}

.transition-text-colour {
  transition: color $transition-hover-out;

  &:hover {
    transition: color 100ms;
  }
}

.transition-all-colours {
  transition: color $transition-hover-out, background-color $transition-hover-out, border-color $transition-hover-out;

  &:hover {
    transition: color 100ms, background-color 100ms, border-color 100ms;
  }
}

.hidden,
.standalone .hide-on-standalone {
  display: none !important;
}
