h1,
h2,
h3,
h4,
h5,
h6 {
  padding-bottom: 1rem;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.25rem;
}

h3 {
  font-size: 2.75rem;
}

h4 {
  font-size: 2.25rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1.8rem;
}

dt {
  width: calc(60% - #{($gutter-mobile / 2)});
  padding-right: ($gutter-mobile / 2);
}

dd {
  width: calc(40% - #{($gutter-mobile / 2)});
  padding-left: ($gutter-mobile / 2);
}
