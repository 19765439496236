.container {
  &:not(.columns-on-tiny) {
    > .column,
    > [class^="flex-"],
    > [class*=" flex-"] {
      width: 100%;

      @include flex(none);
      @include flex-order(0);
    }
  }
}

.flex-auto-tiny {
  @include flex(1 1 auto);
}

.width-full-tiny {
  width: 100%;
}

//@for $i from 0 through 2 {
//  .flex-#{$i}-tiny {
//    @include flex($i 0);
//  }
//}

@for $i from 2 through 3 {
  .width-#{floor(100 / $i)}-tiny {
    width: (100% / $i);
  }

  @for $j from 1 through $i - 1 {
    .width-#{$j}-#{$i}-tiny {
      width: ((100% / $i) * $j);
    }
  }
}
