






















































































































@import "../../styles/env";

.locked-album {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.locked-notice {
  overflow: hidden;

  border-radius: $border-radius;

  @include shadow-2;

  .card {
    margin-bottom: 0;

    align-items: center;

    border-radius: 0;

    z-index: 1;

    .card-content {
      padding: 2rem;

      /*text-align: right;*/

      align-self: stretch;

      .card-title {
        /*text-align: left;*/
      }

      .block {
        margin: 0;

        align-self: flex-end;
      }
    }
  }
}

.form,
.success {
  margin-top: 2rem;
}
